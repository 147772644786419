var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-fab-transition",
        [
          _vm.isTimerRunning && _vm.enabledTimer
            ? _c(
                "v-btn",
                {
                  staticClass: "stemp-time-runner my-4",
                  attrs: {
                    color: "grey darken-3",
                    depressed: "",
                    fab: "",
                    dark: "",
                    top: "",
                    right: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/stemp")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.time))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }